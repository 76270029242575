<template>
  <main class="main-page">
    <template v-if="showHeader">
      <section class="page-section">
        <div class="container">
          <div class="grid align-items-center justify-content-center mt-6">
            <div class="col-12 md:col-8 lg:col-8 comp-grid">
              <div class="flex align-items-center">
                <div v-if="!isSubPage" class="col-fixed">
                  <Button @click="$router.go(-1)" label="" icon="pi pi-arrow-left"
                    class="p-button-sm p-button-rounded px-4" />
                </div>
                <div class="col-12 md:col-10">
                  <div class="text-2xl text-primary font-bold">Register</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section mb-5">
      <div class="container">
        <div class="flex align-items-center justify-content-center">
          <div class="col-12 md:col-8 lg:col-8 comp-grid">
            <div>
              <form ref="observer" tag="form" @submit.prevent="submitForm()" :class="{ card: !isSubPage }" class="">
                <div class="grid">
                  <div class="col-12 md:col-6">
                    <div class="mb-2 font-bold text-sm">Mobile Number *</div>
                    <div class="p-inputgroup flex-1">
                      <span class="p-inputgroup-addon">+233</span>
                      <InputText ref="ctrlmobile_number" v-tooltip.top.focus="'024XXXXXXX'" v-model.trim="formData.mobile_number" label="Mobile Number"
                        type="text" minLength="10" maxLength="10" placeholder="Enter Mobile Number"  pattern="^[0-9]+$"
                         class="w-full"
                        :class="getErrorClass('mobile_number')">
                      </InputText>
                      <small v-if="isFieldValid('mobile_number')" class="p-error">{{ getFieldError("mobile_number")
                        }}</small>
                    </div>
                  </div>
                  <div class="col-12 md:col-6">
                    <div class="mb-2 font-bold text-sm">Email *</div>
                    <div class="">
                      <InputText ref="ctrlemail" v-tooltip.top.focus="'example@email.com'" v-model.trim="formData.email" label="Email" type="email"
                        placeholder="Enter Email" class="w-full" :class="getErrorClass('email')">
                      </InputText>
                      <small v-if="isFieldValid('email')" class="p-error">{{
                        getFieldError("email")
                        }}</small>
                    </div>
                  </div>
                  <div class="col-12 md:col-6">
                    <div class="mb-2 font-bold text-sm">Password *</div>
                    <div class="">
                      <Password ref="ctrlpassword" v-model="formData.password" label="Password"
                        placeholder="Enter Password" class="w-full" inputClass="w-full" toggleMask :feedback="false"
                        :class="getErrorClass('password')" />
                      <small v-if="isFieldValid('password')" class="p-error">{{
                        getFieldError("password")
                        }}</small>
                    </div>
                  </div>
                  <div class="col-12 md:col-6">
                    <div class="mb-2 font-bold text-sm">Confirm Password *</div>
                    <div class="">
                      <Password class="w-full" :class="getErrorClass('password')" inputClass="w-full" :feedback="false"
                        toggleMask ref="ctrlconfirmpassword" v-model="formData.confirm_password"
                        label="Confirm Password" placeholder="Confirm Password" />
                    </div>
                  </div>
                </div>
                <div v-if="showSubmitButton" class="text-center my-3">
                  <Button type="submit" label="Submit" class="px-5" :loading="saving" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script setup>
  import { computed, reactive, toRefs, onMounted } from "vue";
  import useVuelidate from "@vuelidate/core";
  import { required, email, sameAs } from "@/services/validators";
  import { useApp } from "@/composables/app.js";
  import { useAuth } from "@/composables/auth";
  import { useAddPage } from "@/composables/addpage.js";
  const props = defineProps({
    pageName: {
      type: String,
      default: "users",
    },
    routeName: {
      type: String,
      default: "usersuserregister",
    },
    apiPath: {
      type: String,
      default: "auth/register",
    },
    submitButtonLabel: {
      type: String,
      default: "Submit",
    },
    formValidationError: {
      type: String,
      default: "Form is invalid",
    },
    formValidationMsg: {
      type: String,
      default: "Please complete the form",
    },
    msgTitle: {
      type: String,
      default: "Create Record",
    },
    msgAfterSave: {
      type: String,
      default: "Record added successfully",
    },
    msgBeforeSave: {
      type: String,
      default: "",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showSubmitButton: {
      type: Boolean,
      default: true,
    },
    redirect: {
      type: Boolean,
      default: true,
    },
    isSubPage: {
      type: Boolean,
      default: false,
    },
    pageData: {
      // use to set formData default values from another page
      type: Object,
      default: () => { },
    },
  });
  const app = useApp();
  const auth = useAuth();
  const formDefaultValues = {
    email: "",
    password: "",
    confirm_password: "",
    photo: "",
    mobile_number: "",
  };
  const formData = reactive({ ...formDefaultValues });
  //form validation rules
  const rules = computed(() => {
    return {
      email: { required, email },
      password: { required },
      confirm_password: {
        required,
        sameAs: sameAs(formData.password, "Password"),
      },
      photo: {},
      mobile_number: { required },
    };
  });
  const v$ = useVuelidate(rules, formData); // form validation
  const page = useAddPage({ props, formData, v$, beforeSubmit, afterSubmit });
  // perform custom validation before submit form
  // set custom form fields
  // return false if validation fails
  function beforeSubmit() {
    return true;
  }
  // after form submited to api
  // reset form data.
  // redirect to another page
  function afterSubmit(response) {
    app.flashMsg(props.msgTitle, props.msgAfterSave);
    page.setFormDefaultValues(); //reset form data
    const loginData = response;
    // user registration completed. navigate to home page
    if (loginData.token) {
      auth.saveLoginData(loginData, false);
      location.href = "/";
    } else {
      app.flashMsg(loginData.message); //show flashMsg if available
      app.navigateTo(loginData.nextpage); // navigate to next page if available
    }
  }
  const { submitted, saving, pageReady } = toRefs(page.state);
  const {
    submitForm,
    getErrorClass,
    getFieldError,
    isFieldValid,
    mapOptionField,
  } = page.methods;
  onMounted(() => {
    const pageTitle = "Register";
    app.setPageTitle(props.routeName, pageTitle); // set browser page title
  });
  // expose page object for other components access
  defineExpose({
    page,
  });
</script>
<style scoped></style>